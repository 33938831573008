<template>
    <v-container class="my-10">
        <v-row align="center" justify="center">
            <v-col cols="8" md="6" lg="5" class="center">
                <v-card class="px-8 py-8 mx-auto">
                    <recov-header :headata="headerdata.activcode"></recov-header>
                    <v-card-text>
                        <v-form ref="forms1">
                            <v-text-field v-model="activcode" outlined dense label="Activation code" type="text"
                                placeholder="john@example.com" @input="$v.activcode.$touch()"
                                @blur="$v.activcode.$touch()" :hide-details="activcodeErrors.isvalid"
                                :error-messages="activcodeErrors.value" clearable class="mb-3">
                            </v-text-field>
                            <div class="d-flex align-center justify-space-between flex-wrap mt-2">
                                <v-btn block color="primary" @click="sendActivationCodeSubmit" class="px-10"
                                    :loading="loadingactiv">
                                    Send activation code
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                    <!-- divider -->

                    <v-card-text class="d-flex align-center mt-2">
                        <v-divider></v-divider>
                        <span class="mx-5">or</span>
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-card-text class="d-flex align-center justify-center flex-wrap mt-1">
                        <span>
                            Not received activation code yet?
                        </span>
                        <v-btn disabled color="primary" :loading="loadingresent" text @click="resentactivationcode()" class="text-transform-none ml-1">
                            Resent code
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import RecovHeader from './RecovHeader.vue'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        activcode: { required }
    },
    components: {
        RecovHeader,
    },
    data() {
        return {
            loadingactiv: false,
            loadingresent: false,
            isResetWithEmail: true,
            activcode: ''
        }
    },
    computed: {
        activcodeErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.activcode.$dirty) return errors
            !this.$v.activcode.required && errors.value.push('Activation code required for account validation..!') && (errors.isvalid = false)
            return errors
        },
    },

    methods: {
        checkValidationForm(stape) {
            if (this.activcodeErrors.isvalid) { return true }
            else { return false }
        },
        resentactivationcode() { 
            this.loadingresent = true
        },
        sendActivationCodeSubmit() {
            var formactiv = new FormData();
            this.loadingactiv = true
            formactiv.append("activationcode", this.activcode);
            Drequest.api(`user.activateaccount?user_id=${this.$store.getters.getInitActivationCode.id}`)
                .data(formactiv)
                .post((response) => {
                    switch (response.success) {
                        case true:
                            this.loadingactiv = false;
                            this.dialog = false
                            this.showactivatedbtn = response.user.is_activate
                            this.$store.commit("setUser", {});
                            this.$fire({
                                type: "success",
                                text: response.detail,
                            })
                            this.$router.push({ name: 'user.home' })
                            break;
                        default:

                            this.loadingactiv = false;
                            this.$fire({
                                type: "error",
                                text: "Bad activation code.! \n please check your email adress.!",
                            })
                            break;
                    }
                })
                .catch((err) => {
                    window.console.log(err)
                    this.loadingactiv = false;
                });
        }
    },
    beforeMount() {
        if (this.$store.getters.getInitActivationCode.init === false) {
            this.$router.push({ name: 'login' })
        }
    },
    setup() {
        let headerdata = {
            activcode: {
                title: "Account validation",
                subtitle: "Check your emails, We have sent a recovery code to your email address"
            },
        }
        const icons = { mdiEyeOutline, mdiEyeOffOutline }
        return {
            icons, headerdata
        }
    },

}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

//@override
.v-card {
    border-radius: 1em !important;
}

h1 {
    margin: 0 !important;
}
</style>
